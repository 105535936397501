import React from "react";
import styles from "./PropertiesItem.module.scss";
import cn from "classnames";
import { IProperties } from "../../index";
import { useThemeContext } from "utils/themeContextWrapper";
import { useTranslation } from "react-i18next";

const PropertiesItem: React.FC<IProperties> = ({
  icon,
  iconDark,
  title,
  laptopIcon,
  laptopIconDark,
}) => {
  const { t } = useTranslation();

  const { theme } = useThemeContext();

  return (
    <div className={styles.property}>
      <b>{t(`plugins.${title}`)}</b>
      {laptopIcon ? (
        <img
          className={styles.icon_laptop}
          src={`/img/pluginsBlock/props/${theme === "dark" ? laptopIconDark : laptopIcon}`}
          alt={title}
        />
      ) : null}

      <img
        className={cn(styles.icon_tablet, { [styles.icon_show]: !laptopIcon })}
        src={`/img/pluginsBlock/props/${theme === "dark" ? iconDark : icon}`}
        alt={title}
      />
    </div>
  );
};

export default PropertiesItem;
