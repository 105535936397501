import React from "react";
import styles from "./AcceptPaymentsStepBlock.module.scss";
import { useTranslation } from "next-i18next";

import AcceptPaymentsBody from "./AcceptPaymentsBody";
import { Button } from "components/shared/Button/Button";
import { getLocaleLink } from "../../../utils/getLocaleLink";

const AcceptPaymentsStepBlock: React.FC = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <div className={styles.block__wrapper}>
      <div className={styles.block_header}>
        <img src="/img/stars__bg.png" alt="bg" />
        <h2>{t("Accept.How to accept crypto payments with Cryptomus")}</h2>

        <Button
          linkProps={{
            type: "external",
            href: getLocaleLink("https://app.cryptomus.com/signup", i18n.language),
          }}
          className={styles.block_header__button}
          width={217}
          appearance="primary"
          white
          size="l"
        >
          {t("Get Started").toUpperCase()}
        </Button>
      </div>

      <AcceptPaymentsBody />
    </div>
  );
};

export default AcceptPaymentsStepBlock;
