import React from "react";
import styles from "./MercuryoBanner.module.scss";
import Image from "next/image";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useThemeContext } from "../../../../utils/themeContextWrapper";
import { useTranslation } from "next-i18next";

export const MercuryoBanner = () => {
  const isTablet = useMediaQuery("(max-width:768px)");
  const { theme } = useThemeContext();
  const { t } = useTranslation("common");

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        {isTablet ? (
          theme === "dark" ? (
            <Image src="/img/mercuryo1-tablet-dark.png" fill={true} objectFit="cover" alt="bg" />
          ) : (
            <Image src="/img/mercuryo1-tablet.png" fill={true} objectFit="cover" alt="bg" />
          )
        ) : theme === "dark" ? (
          <Image src="/img/mercuryo1-dark.png" fill={true} alt="bg" />
        ) : (
          <Image src="/img/mercuryo1.png" fill={true} alt="bg" />
        )}
      </div>

      <div className={styles.description}>
        <h2 className={styles.title}>Mercuryo</h2>
        <p className={styles.text}>
          {t("Your customers can make payments even if they dont have any cryptocurrency")}
        </p>
      </div>

      <div className={styles.block}>
        {theme === "dark" ? (
          <Image src="/img/mercuryo-banner-block-dark.svg" fill={true} alt="block" />
        ) : (
          <Image src="/img/mercuryo-banner-block.svg" fill={true} alt="block" />
        )}
      </div>
    </div>
  );
};
