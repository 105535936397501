import React from "react";
import styles from "./FeaturesHeader.module.scss";
import { useTranslation } from "react-i18next";

const featureList: string[] = [
  "No rolling reserves",
  "Global coverage",
  "Zero chargebacks",
  "Instant transactions",
];

const FeaturesHeader: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.features__header}>
      <h2>{t("features.What are Cryptomus crypto payment gateway features?")}</h2>
      <div className={styles.features__header_features}>
        {featureList.map((item, index, arr) => (
          <p key={item}>
            {t(`features.${item}`)}

            {arr?.length - 1 !== index ? <span>/</span> : null}
          </p>
        ))}
      </div>
    </div>
  );
};

export default FeaturesHeader;
