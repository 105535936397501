import React from "react";
import styles from "./AppBanner.module.scss";
import cn from "classnames";
import useMediaQuery from "@mui/material/useMediaQuery";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useThemeContext } from "../../../../utils/themeContextWrapper";
import AppStoreBtn from "../../AppStoreBtn";

const longLangList = ["es", "de", "fr", "ru", "uk", "kk", "pl"];

const AppBanner = () => {
  const { t, i18n } = useTranslation("common");
  const { theme } = useThemeContext();
  const isLaptop = useMediaQuery("(max-width:1024px)");
  const isTablet = useMediaQuery("(max-width:768px)");

  const getImage = () => {
    if (!isLaptop || isTablet)
      return (
        <Image
          src="/img/CryptomusAppBanner/CryptomusAppBg.png"
          alt="Cryptomus App banner image"
          width={2430}
          height={1650}
          className={cn(styles.app_banner__image, {
            [styles.long_lang]: longLangList.includes(i18n.language),
          })}
        />
      );

    return (
      <Image
        src="/img/CryptomusAppBanner/CryptomusAppBgTablet.png"
        alt="Cryptomus App banner image"
        width={1797}
        height={1650}
        className={styles.app_banner__image}
      />
    );
  };

  return (
    <div
      className={cn(styles.app_banner, {
        [styles.long_lang]: longLangList.includes(i18n.language),
      })}
    >
      <div className={styles.app_banner__content}>
        <h2 className={styles.content__title}>{t("Cryptomus on the App Store")}</h2>
        <p className={styles.content__descr}>
          {t("Download the app and make using the service even more convenient")}
        </p>
        <AppStoreBtn locale={i18n.language} theme={theme} />
      </div>
      {getImage()}
    </div>
  );
};

export default AppBanner;
