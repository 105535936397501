import React, { ReactNode } from "react";
import styles from "./AcceptPaymentStep.module.scss";

import AcceptPaymentStep from "./AcceptPaymentStep/index";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

export interface IStep {
  id: number;
  title: string;
  text: string;
  stepType: "small" | "large";
  windowTitle?: string;
  windowBody: ReactNode;
}

const steps: IStep[] = [
  {
    id: 0,
    title: "Start your journey",
    text: "Join Cryptomus by signing up with a non-custodial wallet, phone number, or email to start accepting payments",
    stepType: "small",
    windowBody: <FirstStep />,
  },
  {
    id: 1,
    title: "Create your first merchant",
    text: "Set up your first merchant account with Cryptomus to start accepting payments and get a reliable Business Wallet",
    stepType: "small",
    windowTitle: "Create merchant",
    windowBody: <SecondStep />,
  },
  {
    id: 2,
    title: "Generate API keys",
    text: "In your merchant account, generate API keys and integrate Cryptomus using API, SDK tools, and ready-made plugins",
    stepType: "large",
    windowTitle: "Your API keys",
    windowBody: <ThirdStep />,
  },
];

const AcceptPaymentsBody: React.FC = () => {
  return (
    <div className={styles.steps__wrapper}>
      {steps.map((item) => (
        <AcceptPaymentStep
          title={item.title}
          text={item.text}
          stepType={item.stepType}
          windowBody={item.windowBody}
          windowTitle={item.windowTitle}
          id={item.id}
          key={item.id}
        />
      ))}
    </div>
  );
};

export default AcceptPaymentsBody;
