import React from "react";
import styles from "./PluginItem.module.scss";
import { IPlugin } from "../../index";
import IconArrow from "/public/img/pluginsBlock/arrowRight.svg";
import { useThemeContext } from "utils/themeContextWrapper";
import Link from "next/link";

const PluginItem: React.FC<IPlugin> = ({ title, icon, iconDark }) => {
  const { theme } = useThemeContext();

  return (
    <Link href="/plugins" className={styles.plugin}>
      <img
        src={`/img/pluginsBlock/plugins/${iconDark && theme === "dark" ? iconDark : icon}`}
        alt={title}
      />

      <div className={styles.footer}>
        <div className={styles.footer__text}>
          <b>{title}</b>
        </div>

        <div className={styles.arrow__wrapper}>
          <IconArrow />
        </div>
      </div>
    </Link>
  );
};

export default PluginItem;
