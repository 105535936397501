import React from "react";
import styles from "./SecondStep.module.scss";
import { useTranslation } from "next-i18next";
import { Button } from "components/shared/Button/Button";
import { getLocaleLink } from "../../../../../utils/getLocaleLink";

const SecondStep: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.block__wrapper}>
      <p className={styles.input__wrapper}>
        <label htmlFor="firstMerchant">{t("Accept.Enter name")}</label>
        <input disabled type="text" placeholder={t("Accept.First merchant")} name="firstMerchant" />
      </p>

      <div className={styles.btn_bg}>
        <Button
          linkProps={{
            type: "external",
            href: getLocaleLink("https://app.cryptomus.com/signup", i18n.language),
          }}
        >
          <span>{t("Accept.Create")}</span>
        </Button>
      </div>
    </div>
  );
};

export default SecondStep;
