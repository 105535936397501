import React from "react";
import styles from "./ThirdStep.module.scss";
import cn from "classnames";
import { useTranslation } from "next-i18next";
import localFont from "next/font/local";

interface IApiKey {
  title: string;
  value: string;
  isValueActive?: boolean;
  btnTitle?: string;
}

const apiKeys: IApiKey[] = [
  {
    title: "Status",
    value: "Active",
    isValueActive: true,
  },
  {
    title: "Payment API key",
    value: "kTaz9Eh*********OBh9aG5",
    btnTitle: "Regenerate the Payment API key",
  },
  {
    title: "Payout API key",
    value: "kTaz9Eh*********OBh9355",
    btnTitle: "Regenerate the Payout API key",
  },
];

const SourceCodePro = localFont({
  src: [
    {
      path: "../../../../../public/fonts/SourceCodePro/SourceCodePro-Black.ttf",
      weight: "900",
      style: "normal",
    },
    {
      path: "../../../../../public/fonts/SourceCodePro/SourceCodePro-ExtraBold.ttf",
      weight: "800",
      style: "normal",
    },
    {
      path: "../../../../../public/fonts/SourceCodePro/SourceCodePro-Bold.ttf",
      weight: "bold",
      style: "normal",
    },
    {
      path: "../../../../../public/fonts/SourceCodePro/SourceCodePro-Light.ttf",
      weight: "200",
      style: "normal",
    },
    {
      path: "../../../../../public/fonts/SourceCodePro/SourceCodePro-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../../../../public/fonts/SourceCodePro/SourceCodePro-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../../../public/fonts/SourceCodePro/SourceCodePro-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
  ],
});

const ThirdStep: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.block__wrapper, SourceCodePro.className)}>
      {apiKeys.map((item) => (
        <div key={item.title} className={styles.block__item}>
          <p>{t(`Accept.${item.title}`)}</p>

          <div className={styles.block__item__value__wrapper}>
            <p className={cn({ [styles.value_active]: item.isValueActive })}>
              {t(`Accept.${item.value}`, item.value)}
            </p>

            {item.btnTitle?.length ? (
              <div className={styles.btn_bg}>
                <button>{t(`Accept.${item.btnTitle}`)}</button>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ThirdStep;
