import { useTranslation } from "next-i18next";
import React from "react";
import SupportCoinsSlider from "./SupportCoinsSlider";
import styles from "./SupportCoinsBlock.module.scss";

const SupportCoinsBlock: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.support__coins__container}>
      <h2>{t("Supported coins and blockchains")}</h2>

      <SupportCoinsSlider />
    </div>
  );
};

export default SupportCoinsBlock;
