export interface IPlugin {
  img: string;
  title: string;
  version: string;
}

export interface IMedia {
  url?: string;
  img: string;
}

export const pluginList: IPlugin[] = [
  {
    img: "/img/logo-presta-shop.svg",
    title: "PrestaShop",
    version: "Version · 1.00",
  },
  {
    img: "/img/logo-opencart.svg",
    title: "Opencart",
    version: "Version · 1.00",
  },
  {
    img: "/img/logo-woo-commerce.svg",
    title: "WooCommerce",
    version: "Version · 1.00",
  },
];

export const mediaList: IMedia[] = [
  {
    img: "bitcoin.svg",
  },
  {
    url: "https://www.bloomberg.com/press-releases/2023-02-15/cryptomus-the-all-in-one-solution-for-accepting-cryptocurrency-payments",
    img: "bloomberg.svg",
  },
  {
    url: "https://finance.yahoo.com/news/cryptomus-one-solution-accepting-cryptocurrency-190000004.html",
    img: "yahoo-finance.svg",
  },
  {
    url: "https://www.benzinga.com/amp/content/30930648",
    img: "benzinga.svg",
  },
  {
    url: "https://t.me/toncoin/668",
    img: "ton.svg",
  },
  {
    url: "https://cointelegraph.com/press-releases/cryptocurrency-payment-processor-cryptomus-is-ready-to-become-a-game-changer-in-the-market",
    img: "cointelegraph.svg",
  },
  {
    // url: "https://www.morningstar.com/news/globe-newswire/8750246/cryptomus-the-all-in-one-solution-for-accepting-cryptocurrency-payments",
    img: "morningstar.svg",
  },
  {
    url: "https://beincrypto.com/cryptomus-officially-records-over-100000-transactions/",
    img: "beincrypto.png",
  },
  {
    // url: "https://www.marketwatch.com/press-release/cryptomus-launches-recurring-payments-feature-for-cryptocurrency-transactions-2023-04-06",
    img: "market-watch.svg",
  },
  {
    url: "https://www.chaingpt.org/",
    img: "chaingpt.svg",
  },
  {
    url: "https://www.adspower.com/ru/?source=Cryptomus",
    img: "adspower.svg",
  },
  {
    url: "https://aeza.net/?ref=cryptomus",
    img: "aeza.svg",
  },
  {
    url: "https://sendpulse.com/?utm_source=cryptomus.com&utm_medium=referral&utm_campaign=cryptomus-integration%25C2%25AO%7C",
    img: "sendpulse.svg",
  },
  {
    url: "https://mercuryo.io/",
    img: "mercuryo.svg",
  },
  {
    url: "https://www.dextools.io/app/polygon/pair-explorer/0xe8a715d97388fab85ab0cff707a63dd79096c9e9?t=1711008168862",
    img: "DEXTools.svg",
  },
  {
    url: "https://bestchange.com/ ",
    img: "bestchange.svg",
  },
];

interface ICoin {
  id: number;
  icon: string;
  bgColor: string;
  url: string;
}

export const coinList: ICoin[] = [
  {
    id: 0,
    icon: "bitcoin_1.svg",
    bgColor: "#F7931A",
    url: "/blog/accept-usdt-as-a-business-how-to-get-paid-in-tether-on-your-website",
  },
  {
    id: 1,
    icon: "dash.svg",
    bgColor: "#008CE7",
    url: "/blog/accept-dash-as-a-business-how-to-get-paid-in-dash-on-your-website",
  },
  {
    id: 2,
    icon: "tether.svg",
    bgColor: "#50AF95",
    url: "/blog/accept-usdt-as-a-business-how-to-get-paid-in-tether-on-your-website",
  },
  {
    id: 3,
    icon: "polygon.svg",
    bgColor: "#8247E5",
    url: "/blog/accept-matic-as-a-business-how-to-get-paid-in-polygon-on-your-website",
  },
  {
    id: 4,
    icon: "binance.svg",
    bgColor: "#F0B90B",
    url: "/blog/accept-bnb-as-a-business-how-to-get-paid-in-binance-coin-on-your-website",
  },
  {
    id: 5,
    icon: "bitcoin_2.svg",
    bgColor: "#4BCF51",
    url: "/blog/accept-btc-as-a-business-how-to-get-paid-in-bitcoin-on-your-website",
  },
  {
    id: 6,
    icon: "etherium.svg",
    bgColor: "#627EEA",
    url: "/blog/accept-eth-as-a-business-how-to-get-paid-in-ether-on-your-website",
  },
  {
    id: 7,
    icon: "litecoin.svg",
    bgColor: "#CBC6C6",
    url: "/blog/accept-ltc-as-a-business-how-to-get-paid-in-litecoin-on-your-website",
  },
  {
    id: 8,
    icon: "busd.svg",
    bgColor: "#F0B90B",
    url: "/blog/accept-busd-as-a-business-how-to-get-paid-in-bitcoin-usd-on-your-website",
  },
  {
    id: 9,
    icon: "ton.svg",
    bgColor: "#0088CC",
    url: "/blog/accept-ton-as-a-business-how-to-get-paid-in-toncoin-on-your-website",
  },
  {
    id: 10,
    icon: "trx.svg",
    bgColor: "#E50915",
    url: "/blog/accept-trx-as-a-business-how-to-get-paid-in-tron-on-your-website",
  },
];

export const BLOG_POSTS_FETCH_KEY = 'BlogPosts';
