import React, { useState } from "react";
import styles from "./AcceptPaymentsStepBlock.module.scss";
import AcceptPaymentStepWindow from "./AcceptPaymentStepWindow/index";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "next-i18next";

import { IStep } from "../index";
import IconDown from "public/img/icon-arrow-down.svg";

const AcceptPaymentStep: React.FC<IStep> = ({
  title,
  text,
  stepType,
  windowBody,
  windowTitle = "",
  id,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={cn(styles.block__wrapper, { [styles.block__wrapper_large]: stepType === "large" })}
    >
      <AcceptPaymentStepWindow title={windowTitle}>{windowBody}</AcceptPaymentStepWindow>
      <div className={styles.header__wrapper}>
        <div onClick={() => setIsOpen(!isOpen)} className={styles.header__accordion_row}>
          <p className={styles.header__wrapper__title}>
            {id + 1}. <span>{t(`Accept.${title}`)}</span>
          </p>

          <IconDown
            className={cn(styles.accordion__btn, { [styles.accordion__btn_active]: isOpen })}
          />
        </div>

        <div className={styles.item_mobile}>
          <AnimatePresence exitBeforeEnter>
            {isOpen ? (
              <motion.div
                className={styles.feature__item__body}
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: { height: "auto" },
                  collapsed: { height: 0 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                <p className={styles.header__wrapper__text}>{t(`Accept.${text}`)}</p>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
        <div className={styles.item_desktop}>
          <p className={styles.header__wrapper__text}>{t(`Accept.${text}`)}</p>
        </div>
      </div>
    </div>
  );
};

export default AcceptPaymentStep;
