import React from "react";

import { useTranslation } from "next-i18next";
import { Button } from "components/shared/Button/Button";

import { getLocaleLink } from "../../../../utils/getLocaleLink";

import clsx from "clsx";

import styles from "./RaisingAPRBunner.module.scss";

const longLanguages = ["es", "pt", "pl", "uz", "ko", "ja"];
const superLongLanguage = ["uk", "kk", "fr", "de", "ru"];

export const RaisingAPRBunner = () => {
  const { t, i18n } = useTranslation("common");

  const isLongLanguage = longLanguages.includes(i18n.language);
  const isSuperLongLanguage = superLongLanguage.includes(i18n.language);

  return (
    <div className={styles.crms__banner}>
      <div className={styles.content}>
        <div className={styles.information}>
          <h2
            className={clsx(styles.title, {
              [styles.longLanguage]: isLongLanguage,
              [styles.superLongLanguage]: isSuperLongLanguage,
            })}
          >
            {t("We're raising the APR up to 20% on TRX staking")}
          </h2>

          <p
            className={clsx(styles.description, {
              [styles.longLanguage]: isLongLanguage,
              [styles.superLongLanguage]: isSuperLongLanguage,
            })}
          >
            {t(
              "Now APR is increased by 4X, it's your time to start earning passive income on staking",
            )}
          </p>
        </div>

        <Button
          className={styles.btn}
          size="m"
          linkProps={{
            type: "external",
            href: getLocaleLink(
              "https://cryptomus.com/blog/staking-trx-with-cryptomus-step-by-step-guide",
              i18n.language,
            ),
          }}
        >
          {t("Learn more")}
        </Button>
      </div>
    </div>
  );
};
