import * as React from "react";
import styles from "./AccordionItem.module.scss";
import IconPlus from "public/img/icon-plus.svg";
import IconMinus from "public/img/icon-minus.svg";
import { useTranslation } from "react-i18next";
import { IAccordion } from "../index";
import { ReactNode } from "react";

interface IAccordionItemBody {
  activeRow: IAccordion | null;
  setActiveRow: React.Dispatch<React.SetStateAction<IAccordion | null>>;
  row: IAccordion;
  children: ReactNode;
}

const AccordionItem: React.FC<IAccordionItemBody> = ({
  activeRow,
  setActiveRow,
  row,
  children,
}) => {
  const { t } = useTranslation("common");

  const handleClick = (): void => {
    setActiveRow(row);
  };

  const handleMouseOver = () => {
    setActiveRow(row);
  };

  return (
    <div key={row.id} className={styles.accordion__row} onClick={handleClick}>
      <div className={styles.header__wrapper} onMouseOver={handleMouseOver}>
        <div className={styles.header__top__wrapper}>
          <div className={styles.header__top}>
            <p className={styles.title}>{t(`accordion.${row.title}`)}</p>
            {activeRow?.id === row.id ? <IconMinus className={styles.icon__minus} /> : <IconPlus />}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
