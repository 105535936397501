import React, { ReactNode } from "react";
import styles from "./AcceptPaymentStepWindow.module.scss";
import { useTranslation } from "next-i18next";

interface IAcceptPaymentStepWindowProps {
  children: ReactNode;
  title: string;
}

const AcceptPaymentStepWindow: React.FC<IAcceptPaymentStepWindowProps> = ({ children, title }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.block__wrapper}>
      <div className={styles.header}>
        <div className={styles.circles}>
          {[0, 1, 2].map((item) => (
            <span key={item} className={styles.circle} />
          ))}
        </div>

        {title ? <p>{t(`Accept.${title}`)}</p> : null}
      </div>

      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default AcceptPaymentStepWindow;
