import { motion } from "framer-motion";
import React from "react";

import ExclamationIcon from "public/img/icon-alert__black.svg";

import styles from "./CaptionHint.module.scss";

import cn from "classnames";
import { useTranslation } from "next-i18next";

const variantsHint = {
  active: { opacity: 1, height: "auto" },
  disabled: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  },
};

export interface ICaptionHintProps {
  title: string;
  children?: React.ReactNode;
  type?: "warning" | "error" | "success" | "notice";
  initialRender?: boolean;
  className?: string;
  withoutBg?: boolean;
  unmounteRender?: boolean;
}

export const CaptionHint = ({
  type,
  title,
  children,
  className,
  initialRender,
  unmounteRender,
  withoutBg,
}: ICaptionHintProps): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <motion.div
      initial={initialRender ? "disabled" : false}
      animate={"active"}
      exit={!unmounteRender ? "" : "disabled"}
      variants={variantsHint}
    >
      <motion.div
        className={cn(styles.caption_hint, className, {
          [styles.hideBg]: withoutBg,
          [styles.warning]: type === "warning",
          [styles.error]: type === "error",
          [styles.success]: type === "success",
          [styles.notice]: type === "notice",
        })}
      >
        {type && (
          <div className={styles.hint__icon}>
            <ExclamationIcon />
          </div>
        )}
        <div>
          {title && <div className={styles.hint__title}>{t(title)}</div>}
          <div className={styles.hint__description}>{children}</div>
        </div>
      </motion.div>
    </motion.div>
  );
};
