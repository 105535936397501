import React, { ReactNode } from "react";
import styles from "./FeaturesBody.module.scss";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import cn from "classnames";

import IconSecurity from "/public/img/main-feature/security.svg";
import IconSettings from "/public/img/main-feature/settings.svg";
import IconPercent from "/public/img/main-feature/percent.svg";
import IconChat from "/public/img/main-feature/chat.svg";
import IconDocument from "/public/img/main-feature/document.svg";
import FeatureItem from "./FeatureItem";

export interface IFeaturesList {
  title: string;
  icon: ReactNode;
  text: string;
}

const featuresList: IFeaturesList[] = [
  {
    title: "Volatility protection",
    icon: <IconSecurity />,
    text: "Automatic conversion of incoming payments into stablecoins will protect against cryptocurrency volatility, same as the Withdrawal auto-convert feature, that allows you to withdraw your crypto in a preferred currency – and both features are completely free to use!",
  },
  {
    title: "Support team",
    icon: <IconChat />,
    text: "The support team, that is available in Telegram, by email, right on our website in the form of tickets or as a personal manager for our merchants, is always there to solve issues and answer your customers' questions instantly",
  },
  {
    title: "Transactions status management",
    icon: <IconSettings />,
    text: "Adjust the allowed payment accuracy, view if an invoice has been overpaid or underpaid and send an additional invoice to collect a remaining amount",
  },
  {
    title: "Mass payouts",
    icon: <IconDocument />,
    text: "Make mass payouts to thousands of addresses with automatic conversion in just one moment",
  },
  {
    title: "Flexible commissions for each coin",
    icon: <IconPercent />,
    text: "Set additional commissions or add a discount for chosen coins",
  },
];

const FeaturesBody: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.features__body}>
      <div className={styles.features__items}>
        {featuresList.map((item) => (
          <FeatureItem key={item.title} title={item.title} text={item.text} icon={item.icon} />
        ))}

        <Link
          className={cn(styles.features__body__btn, styles.features_desktop)}
          href="/processing"
        >
          {t("features.All features")}
        </Link>
      </div>
      <Link className={cn(styles.features__body__btn, styles.features_mobile)} href="/processing">
        {t("features.All features")}
      </Link>
    </div>
  );
};

export default FeaturesBody;
