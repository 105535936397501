import React from "react";
import { Button } from "components/shared/Button/Button";
import styles from "./StartAcceptingBlock.module.scss";
import { useTranslation } from "next-i18next";
import { getLocaleLink } from "../../../utils/getLocaleLink";

const StartAcceptingBlock: React.FC = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <div className={styles.start__accepting__container}>
      <h2>{t("Start accepting payments in crypto right now")}</h2>

      <Button
        linkProps={{
          type: "external",
          href: getLocaleLink("https://app.cryptomus.com/signup", i18n.language),
        }}
        className={styles.button}
        white
      >
        {t("Sign up")}
      </Button>
    </div>
  );
};

export default StartAcceptingBlock;
