import React, { ReactNode, useState } from "react";
import styles from "./FeaturesItem.module.scss";
import { useTranslation } from "react-i18next";
import IconDown from "public/img/icon-arrow-down.svg";
import { AnimatePresence, motion } from "framer-motion";
import cn from "classnames";

interface IFeatureItemProps {
  icon: ReactNode;
  title: string;
  text: string;
}

const FeatureItem: React.FC<IFeatureItemProps> = ({ icon, title, text }) => {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={styles.feature__item}>
      <div className={styles.feature__item__top}>
        <motion.div
          className={styles.feature__item__header}
          initial={false}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={styles.feature__item__header__col}>
            <div className={styles.feature__item__icon}>{icon}</div>
            <h3>{t(`features.${title}`)}</h3>
          </div>
          <IconDown
            className={cn(styles.accordion__btn, { [styles.accordion__btn_active]: isOpen })}
          />
        </motion.div>
        <AnimatePresence exitBeforeEnter>
          {isOpen ? (
            <motion.div
              className={cn(styles.feature__item__body, styles.feature__item__mobile)}
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              <span></span>
              {t(`features.${text}`)}
            </motion.div>
          ) : null}
        </AnimatePresence>

        <div className={cn(styles.feature__item__body, styles.feature__item__desktop)}>
          {t(`features.${text}`)}
        </div>
      </div>
    </div>
  );
};

export default FeatureItem;
