import React from "react";
import styles from "./CRMSBanner.module.scss";
import cn from "classnames";
import Image from "next/image";
import { useThemeContext } from "utils/themeContextWrapper";
import { useTranslation } from "next-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const CRMSBanner = () => {
  const { t, i18n } = useTranslation("common");
  const { theme } = useThemeContext();
  const isTablet = useMediaQuery("(max-width:768px)");

  const getImage = () => {
    if (!isTablet)
      return theme === "light" ? (
        <Image
          width={645}
          height={550}
          alt="CRMS awards"
          src={`/img/CRMSBanner/CRMS-banner-light.png`}
        />
      ) : (
        <Image
          width={645}
          height={550}
          alt="CRMS awards"
          src={`/img/CRMSBanner/CRMS-banner-dark.png`}
        />
      );

    return theme === "light" ? (
      <Image
        width={343}
        height={369}
        alt="CRMS awards"
        src={`/img/CRMSBanner/CRMS-banner-mobile-light.png`}
      />
    ) : (
      <Image
        width={343}
        height={369}
        alt="CRMS awards"
        src={`/img/CRMSBanner/CRMS-banner-mobile-dark.png`}
      />
    );
  };

  return (
    <div
      className={cn(styles.crms, {
        [styles.long_text]: ["es", "de", "fr", "uz", "kk", "ja", "pa"].some(
          (lang) => lang === i18n.language,
        ),
      })}
    >
      <div className={styles.crms__content}>
        <h2 className={styles.content__title}>{t("CRMS airdrops are already in the Awards")}</h2>
        <p className={styles.content__description}>
          {t("What are you waiting for?")}
          <br />
          {t("Start collecting your valuable rewards for completing simple tasks")}
        </p>
      </div>
      <div
        className={cn(styles.crms__image, {
          [styles.long_text]: ["es", "de", "fr", "uz", "kk", "ja", "pa"].some(
            (lang) => lang === i18n.language,
          ),
        })}
      >
        {getImage()}
      </div>
    </div>
  );
};

export default CRMSBanner;
