import * as React from "react";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import styles from "./AccordionContent.module.scss";

interface IAccordionItemBody {
  activeRowId: number | undefined;
  activeComponent: ReactNode;
}

const variants = {
  mobile: {
    open: { opacity: 1, height: "auto" },
    hide: { opacity: 1, height: 0 },
  },
  desktop: {
    open: { opacity: 1, x: "0%" },
    hide: { opacity: 0, x: "-5%" },
  },
};

const AccordionContent: React.FC<IAccordionItemBody> = ({ activeRowId, activeComponent }) => {
  const content = (variant: "desktop" | "mobile") => (
    <motion.div
      key={activeRowId}
      animate="open"
      initial="hide"
      exit="hide"
      variants={variants[variant]}
      transition={{ duration: 0.5 }}
      className={styles.accordionContent_wrapper}
    >
      <div>{activeComponent}</div>
    </motion.div>
  );

  return (
    <>
      <div className={styles.content_mobile}>{content("mobile")}</div>
      <div className={styles.content_desktop}>{content("desktop")}</div>
    </>
  );
};

export default AccordionContent;
