import React from "react";

import styles from "./RedesignBanner.module.scss";
import cn from "classnames";
import { Button } from "components/shared/Button/Button";
import { useTranslation } from "next-i18next";
import { getLocaleLink } from "../../../../utils/getLocaleLink";

const longLangs = ["ru", "de", "kk", "uz"];

const RedesignBanner: React.FC = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <div
      className={cn(styles.redesign, {
        [styles.long_lang]: longLangs.some((lang) => i18n.language === lang),
      })}
    >
      <div className={styles.content}>
        <div className={styles.text}>
          <h2 className={styles.title}>{t("redesign.title")}</h2>
          <p className={styles.description}>{t("redesign.description")}</p>
        </div>
        <Button
          className={styles.btn}
          linkProps={{
            type: "external",
            href: getLocaleLink(
              "https://cryptomus.com/blog/cryptomus-account-redesign-whats-new",
              i18n.language,
            ),
          }}
        >
          {t("Learn more")}
        </Button>
      </div>
    </div>
  );
};

export default RedesignBanner;
