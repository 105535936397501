import * as React from "react";
import styles from "./RecurringPayments.module.scss";
import WalletHeader from "../WalletHeader";
import { CaptionHint } from "components/shared/CaptionHint/CaptionHint";
import BalanceItem from "../BalanceItem";
import { useTranslation } from "next-i18next";
import InvoicePayButton from "../InvoicePayButton";

const RecurringPayments: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.block__wrapper}>
      <div className={styles.header__wrapper}>
        <WalletHeader />

        <CaptionHint
          className={styles.warning__block}
          type="warning"
          title="Confirm the recurring payment"
        >
          <p className={styles.warning__text}>
            {t("accordion.The funds will be debited")} <span>{t("accordion.Every month")}</span>{" "}
            {t("accordion.сancel any time")}
          </p>
        </CaptionHint>

        <BalanceItem title="6 USDT" props="Name · Crypto2" info="You pay network fee" isHideInfo />
      </div>

      <div className={styles.footer}>
        <InvoicePayButton />
      </div>
    </div>
  );
};

export default RecurringPayments;
