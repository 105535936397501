import * as React from "react";
import styles from "./QRCode.module.scss";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "utils/themeContextWrapper";
import Image from "next/legacy/image";

const QRCode: React.FC = () => {
  const { t } = useTranslation("common");
  const { theme } = useThemeContext();

  return (
    <div className={styles.border__wrapper}>
      <div className={styles.block__wrapper}>
        <img
          width={196}
          height={196}
          alt={"qr-code"}
          src={theme === "dark" ? "/img/icon-qr-code_dark.png" : "/img/icon-qr-code.png"}
        />

        <div className={styles.footer}>
          <p className={styles.title}>{t("accordion.Recepient’s wallet address")}</p>
          <p className={styles.code}>0xb3eec98166b930ca8d5f5d2f2bc3egc44</p>
        </div>
      </div>
    </div>
  );
};

export default QRCode;
