import * as React from "react";
import styles from "./InvoiceHeader.module.scss";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import cn from "classnames";
import InvoiceHeader from "/components/shared/InvoiceHeader";
import InvoiceFooter from "/components/shared/InvoiceFooter";
import { api } from "pages/_app";
import { useMutation } from "@tanstack/react-query";
import { IInvoiceHeader } from "/components/shared/InvoiceHeader";

export interface ISelect {
  id: number;
  title: string;
  isDisabled?: boolean;
}

enum InvoiceType {
  Fiat,
  Crypto,
}

enum RequestType {
  usd = "usd",
  trx = "trx",
}

const id: {
  usd: string;
  trx: string;
} = {
  usd: "1d7476c5-f99c-4e52-bd9e-8261aa0ce8fe",
  trx: "0f5a860b-2cff-467b-882b-010b11537016",
};

interface IInvoice {
  id: InvoiceType;
  requestId: string;
  title: string;
  header: IInvoiceHeader;
  selects: ISelect[];
  isPayBtnDisabled?: boolean;
}

const invoices: IInvoice[] = [
  {
    id: InvoiceType.Fiat,
    requestId: id[RequestType.usd],
    title: "Fiat invoice",
    header: {
      title: "Select currency",
      subTitle: "20.00 USD / Select network",
      isHideClipboardBtn: true,
      cashback: 0.02,
    },
    selects: [
      {
        id: 0,
        title: "Select currency",
      },
      {
        id: 1,
        title: "Select network",
        isDisabled: true,
      },
    ],
  },
  {
    id: InvoiceType.Crypto,
    requestId: id[RequestType.trx],
    title: "Crypto invoice",
    header: {
      title: "10.00 TRX",
      subTitle: "TRON (TRC-20)",
      cashback: 0.001,
    },
    selects: [
      {
        id: 0,
        title: "TRON (TRC-20)",
      },
    ],
  },
];

const InvoicesBlock: React.FC = () => {
  const { t } = useTranslation("common");
  const [activeInvoiceId, setActiveInvoiceId] = useState<InvoiceType>(InvoiceType.Crypto);

  const toggleInvoice = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    invoiceId: InvoiceType,
  ): void => {
    e.stopPropagation();
    setActiveInvoiceId(invoiceId);
  };

  const { isLoading: isLoading, mutateAsync: getPaymentLink } = useMutation(
    ["getPaymentLink"],
    (data: { id: string }) =>
      api.post("v1/payment-widget/create-payment", {
        uuid: data.id,
        type: "link",
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.result?.url) {
          //fix for IOS, Safari
          setTimeout(() => {
            window.open(data?.data?.result?.url, "_blank");
          });
        }
      },
    },
  );

  const getLink = (): void => {
    getPaymentLink({ id: invoices[activeInvoiceId].requestId });
  };

  return (
    <div className={styles.block__wrapper}>
      <div className={styles.invoice__wrapper}>
        {invoices.map((item) => (
          <button
            key={item.id}
            className={cn(styles.invoice__item, {
              [styles.invoice__item_active]: item.id === activeInvoiceId,
            })}
            onClick={(e) => toggleInvoice(e, item.id)}
          >
            {t(`${item.title}`)}
          </button>
        ))}
      </div>

      <div className={styles.invoice__body}>
        <InvoiceHeader
          title={invoices[activeInvoiceId]?.header?.title}
          subTitle={invoices[activeInvoiceId]?.header?.subTitle}
          isHideClipboardBtn={invoices[activeInvoiceId]?.header?.isHideClipboardBtn}
          cashback={invoices[activeInvoiceId]?.header?.cashback}
        />

        <InvoiceFooter
          isLoadingLink={isLoading}
          handlePay={getLink}
          isPayBtnDisabled={!!invoices[activeInvoiceId]?.isPayBtnDisabled}
          selects={invoices[activeInvoiceId]?.selects}
        />
      </div>
    </div>
  );
};

export default InvoicesBlock;
