import React, { useState } from "react";
import styles from "./PluginsWrapper.module.scss";
import { IPluginsBlock } from "../index";
import cn from "classnames";
import { Button } from "components/shared/Button/Button";
import PluginItem from "./PluginItem";
import PropertiesItem from "./PropertiesItem/index";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { Modal } from "components/shared/Modal/ModalWindow";
import ContactUsLanding from "components/shared/ContactUsLanding/ContactUsLanding";

const PluginsWrapper: React.FC<IPluginsBlock> = ({
  title,
  subTitle,
  button,
  plugins,
  properties,
  isReverse,
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const [isModalActive, setIsModalActive] = useState<boolean>(false);

  const handleClick = () => {
    if (button.url?.length) {
      router.push(button.url);
    } else {
      setIsModalActive(!isModalActive);
    }
  };

  return (
    <div className={cn(styles.container, { [styles.container_reverse]: isReverse })}>
      <div className={styles.text__wrapper}>
        <div className={styles.text__wrapper_col}>
          <h2>{t(`plugins.${title}`)}</h2>
          {subTitle ? <p>{t(`plugins.${subTitle}`)}</p> : null}
        </div>

        <Button onClick={() => handleClick()} className={cn(styles.button, styles.button_desktop)}>
          {t(`plugins.${button.title}`)}
        </Button>
      </div>

      <div className={styles.blocks__wrapper}>
        {plugins?.length
          ? plugins?.map((item) => (
              <PluginItem
                title={item.title}
                url={item.url}
                icon={item.icon}
                iconDark={item.iconDark}
                key={item.title}
              />
            ))
          : properties?.length
          ? properties?.map((item) => (
              <PropertiesItem
                icon={item.icon}
                iconDark={item.iconDark}
                laptopIcon={item?.laptopIcon}
                laptopIconDark={item?.laptopIconDark}
                title={item.title}
                key={item.title}
              />
            ))
          : null}
      </div>

      <Modal isActive={isModalActive} setIsActive={setIsModalActive}>
        <ContactUsLanding size="xl" modal onSubmit={() => setIsModalActive(false)} />
      </Modal>

      <Button onClick={() => handleClick()} className={cn(styles.button, styles.button_tablet)}>
        {t(`plugins.${button.title}`)}
      </Button>
    </div>
  );
};

export default PluginsWrapper;
