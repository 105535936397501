import React from "react";
import { motion } from "framer-motion";
import styles from "./AccordionItemBody.module.scss";
import { useTranslation } from "react-i18next";
import { IAccordion } from "../index";

interface IAccordionItemBodyProps {
  row: IAccordion;
}

const variants = {
  open: { opacity: 1, height: "auto" },
  collapsed: { opacity: 0, height: 0 },
};

const AccordionItemBody: React.FC<IAccordionItemBodyProps> = ({ row }) => {
  const { t } = useTranslation("common");

  return (
    <motion.div
      className={styles.body}
      key="content"
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <p className={styles.subTitle}>{t(`accordion.${row.subTitle}`)}</p>

      <div className={styles.text_desktop}>
        {row.texts.map((text) => (
          <p key={text} className={styles.text}>
            {t(`accordion.${text}`)}
          </p>
        ))}
      </div>
      <div className={styles.text_mobile}>
        {row.mobileTexts.map((text) => (
          <p key={text} className={styles.text}>
            {t(`accordion.${text}`)}
          </p>
        ))}
      </div>
    </motion.div>
  );
};

export default AccordionItemBody;
