import React, { ReactNode } from "react";
import styles from "./FirstStep.module.scss";
import cn from "classnames";

import { Button } from "components/shared/Button/Button";
import IconTonkeeper from "./icons/tonkeeper.svg";
import IconTelegram from "./icons/telegram.svg";
import IconEmail from "./icons/email.svg";
import IconApple from "./icons/apple.svg";
import IconGoogle from "./icons/google.svg";
import IconFacebook from "./icons/facebook.svg";
import { useTranslation } from "next-i18next";
import { getLocaleLink } from "../../../../../utils/getLocaleLink";

interface IBtn {
  title: string;
  icon: ReactNode;
  iconMobile?: ReactNode;
  type: "gray" | "black";
}

const btns: IBtn[] = [
  {
    title: "Tonkeeper",
    icon: <IconTonkeeper />,
    type: "gray",
  },
  {
    title: "Google",
    icon: <IconGoogle />,
    type: "gray",
  },
  {
    title: "Telegram",
    icon: <IconTelegram />,
    type: "black",
  },
  {
    title: "Apple",
    icon: <IconApple />,
    type: "gray",
  },
  {
    title: "Phone / Email",
    icon: <IconEmail />,
    type: "gray",
  },
  {
    title: "Facebook",
    icon: <IconFacebook />,
    type: "gray",
  },
];

const FirstStep: React.FC = () => {
  const { i18n } = useTranslation();

  return (
    <div className={styles.btns__wrapper}>
      {btns?.map((item) => (
        <div key={item.title} className={styles.btn__bg}>
          <Button
            linkProps={{
              type: "external",
              href: getLocaleLink("https://app.cryptomus.com/signup", i18n.language),
            }}
            className={cn(styles.btn, { [styles.btn__bg_black]: item.type === "black" })}
          >
            <div className={styles.icon}>{item.icon}</div>
            <span>{item.title}</span>
          </Button>
        </div>
      ))}
    </div>
  );
};

export default FirstStep;
