import React from "react";
import styles from "./index.module.scss";
import { Button } from "components/shared/Button/Button";
import IconTg from "./images/icons/tg-icon.svg";

export const CRMSForumsBanner = () => {
  return (
    <div className={styles.crms__banner}>
      <div className={styles.content}>
        <div className={styles.information}>
          <h2 className={styles.title}>Cryptomus Forums</h2>

          <p className={styles.description}>
            Chat with other users and never miss out on service updates and fresh insights in the
            Cryptomus Forums
          </p>
        </div>

        <Button
          className={styles.btn}
          size="m"
          linkProps={{
            type: "external",
            href: "https://t.me/+-8UrcbE1Qsg4ODg6",
          }}
        >
          <IconTg />
          Join Forums
        </Button>
      </div>
    </div>
  );
};
