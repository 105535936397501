import React, { ReactNode } from "react";
import PluginsWrapper from "./PluginsWrapper";
import styles from "./PluginsBlock.module.scss";

export interface IPlugin {
  icon: string;
  title: string;
  iconDark?: string;
  url: string;
}
export interface IProperties {
  title: string;
  icon: ReactNode;
  iconDark: string;
  laptopIcon?: string;
  laptopIconDark?: string;
}
export interface IPluginsBlock {
  isReverse?: boolean;
  title: string;
  subTitle?: string;
  button: {
    title: string;
    url?: string;
    callback?: () => void;
  };
  plugins?: IPlugin[];
  properties?: IProperties[];
}

const pluginsBlockList: IPluginsBlock[] = [
  {
    title: "Cryptomus E-commerce plugins and apps",
    subTitle:
      "Quick integration of the cryptocurrency payment gateway in just a few clicks with ready-made Cryptomus plugins and applications for E-Commerce",
    button: {
      title: "All plugins",
      url: "/plugins",
    },
    plugins: [
      {
        icon: "shopify.svg",
        title: "Shopify",
        url: "",
      },
      {
        icon: "woocommerce.svg",
        title: "WooCommerce",
        url: "",
      },
      {
        icon: "tilda.svg",
        iconDark: "tilda-w.svg",
        title: "Tilda",
        url: "",
      },
      {
        icon: "opencart.svg",
        title: "Opencart",
        url: "",
      },
      {
        icon: "prestashop.svg",
        title: "Prestashop",
        url: "",
      },
      {
        icon: "whmcs.svg",
        title: "WHMCS",
        url: "",
      },
    ],
  },
  {
    isReverse: true,
    title: "Discover new opportunities with our crypto payment solution",
    button: {
      title: "Contact Cryptomus team",
    },
    properties: [
      {
        title: "Stand alongside top companies",
        icon: "mountains.png",
        iconDark: "mountains_dark.png",
      },
      {
        title: "Save up to 80% on processing fees",
        icon: "circle.png",
        iconDark: "circle_dark.png",
      },
      {
        title: "Tap into millions of users worldwide",
        icon: "earth.png",
        iconDark: "earth_dark.png",
        laptopIcon: "earth-laptop.png",
        laptopIconDark: "earth_dark-laptop.png",
      },
      {
        title: "Increase sales conversion",
        icon: "graphics.png",
        iconDark: "graphics_dark.png",
      },
    ],
  },
];

const PluginsBlock: React.FC = () => {
  return (
    <div className={styles.containers__wrapper}>
      {pluginsBlockList?.map((item) => (
        <PluginsWrapper
          key={item.title}
          title={item.title}
          subTitle={item?.subTitle}
          button={item.button}
          plugins={item?.plugins}
          properties={item?.properties}
          isReverse={item.isReverse}
        />
      ))}
    </div>
  );
};

export default PluginsBlock;
