import React from "react";

import styles from "./CRMSCommunity.module.scss";
import { Button } from "components/shared/Button/Button";
import { useTranslation } from "next-i18next";
import { useThemeContext } from "../../../../utils/themeContextWrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconTg from "./img/TG.svg";
import cn from "classnames";
import Image from "next/image";

const CRMSCommunity: React.FC = () => {
  const { t } = useTranslation("common");
  const { theme } = useThemeContext();
  const isTablet = useMediaQuery("(max-width:768px)");

  return (
    <div className={cn(styles.CRMSCommunity, { [styles.CRMSCommunity_dark]: theme === "dark" })}>
      <div className={styles.text}>
        <h2 className={styles.title}>{t("community.title")}</h2>
        <p className={styles.description}>{t("community.description")}</p>
      </div>

      {isTablet && (
        <Image
          width={343}
          height={251}
          src={`/img/CRMSCommunity/bg_l_t.png`}
          alt={"banner img"}
          className={styles.image}
        />
      )}

      <Button
        className={styles.btn}
        linkProps={{
          type: "external",
          href: "https://t.me/+H-SdnkBJhCYzOWNi",
        }}
      >
        <IconTg />
        {t("community.button")}
      </Button>
    </div>
  );
};

export default CRMSCommunity;
