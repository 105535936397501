import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AccordionBlock.module.scss";
import AccordionWrapper from "./AccordionWrapper";

const AccordionBlock: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.accordion__container}>
      <h2 className={styles.accordion__header}>
        {t("accordion.We make it easy for you to plug into digital payments")}
      </h2>

      <AccordionWrapper />
    </div>
  );
};

export default AccordionBlock;
