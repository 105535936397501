import * as React from "react";
import styles from "./Chat.module.scss";
import cn from "classnames";
import IconChatCorner from "/public/img/chat-corner.svg";
import { useTranslation } from "react-i18next";
import Image from "next/image";

const UserIcon = ({ isMe }: { isMe?: boolean }) => {
  if (isMe) {
    return (
      <div className={styles.user_icon}>
        <Image alt={"user-icon"} src="/img/user1.png" unoptimized width={40} height={40} />
      </div>
    );
  }

  return (
    <div className={styles.user_icon}>
      <img alt={"user-icon"} src="/img/user2.png" />
    </div>
  );
};

const ChatWrapper: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.chat__border}>
      <div className={styles.chat__wrapper}>
        <p className={styles.day}>{t("accordion.Today")}</p>

        <div className={styles.chat}>
          <div className={styles.message_friend}>
            <UserIcon isMe />

            <div className={styles.message}>
              <IconChatCorner className={styles.corner} />
              <p className={styles.message__text}>{t("accordion.Hi, where can i pay?")}</p>
              <p className={styles.message__date}>11:47</p>
            </div>
          </div>

          <div className={styles.message__group}>
            <div className={cn(styles.message_me, styles.message_me_null_image)}>
              <div className={styles.message}>
                <p className={styles.message__text}>{t("accordion.Hi, check the invoice here")}</p>
                <p className={styles.message__date}>11:47</p>
              </div>
            </div>

            <div className={styles.message_me}>
              <UserIcon />

              <div className={styles.message}>
                <IconChatCorner className={styles.corner} />
                <p className={cn(styles.message__text, styles.message__link)}>
                  https://pay.cryptomus.com/pay c8c705ef-2035-445c-af70-2bb85badf120
                </p>
                <p className={styles.message__date}>11:47</p>
              </div>
            </div>
          </div>

          <div className={styles.message_friend}>
            <UserIcon isMe />

            <div className={styles.message}>
              <IconChatCorner className={styles.corner} />
              <p className={styles.message__text}>{t("accordion.Paid!")}</p>
              <p className={styles.message__date}>11:47</p>
            </div>
          </div>

          <div className={styles.message_me}>
            <UserIcon />

            <div className={styles.message}>
              <IconChatCorner className={styles.corner} />
              <p className={styles.message__text}>{t("accordion.I see, thanks")}😊</p>
              <p className={styles.message__date}>11:47</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatWrapper;
