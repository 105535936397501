import React from "react";
import styles from "./AcceptBanner.module.scss";
import cn from "classnames";
import { useTranslation } from "next-i18next";
import { Button } from "components/shared/Button/Button";

import { useThemeContext } from "utils/themeContextWrapper";
import { getLocaleLink } from "../../../../utils/getLocaleLink";

interface IAcceptBannerProps {
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const AcceptBanner = ({ setIsModalActive }: IAcceptBannerProps) => {
  const { t, i18n } = useTranslation("common");
  const { theme } = useThemeContext();

  const isDarkTheme = theme === "dark";

  const isLongLang = ["fr", "kk", "pl", "pt", "es", "de", "uz", "ru", "en", "uk", "tr"].some(
    (item) => item === i18n.language,
  );

  return (
    <div
      className={cn(styles.accept__banner_wrapper, {
        [styles.accept__banner_wrapper_dark]: isDarkTheme,
        [styles.accept__banner_wrapper_long_lang]: isLongLang,
      })}
    >
      <div
        className={cn(styles.content, {
          [styles.long_lang]: isLongLang,
        })}
      >
        <h2 className={styles.title}>
          {t("Crypto payments for your business at your fingertips")}
        </h2>
        <p className={styles.description}>
          {t("Accept payments with many benefits and manage your merchant with ease")}
        </p>

        <div className={styles.buttons}>
          <Button
            appearance="primary"
            size="xxl"
            linkProps={{
              href: getLocaleLink("https://app.cryptomus.com/signup", i18n.language),
              type: "external",
            }}
          >
            {t("Sign Up")}
          </Button>
          <Button appearance="secondary" size="xxl" onClick={() => setIsModalActive(true)}>
            {t("Contact us")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AcceptBanner;
