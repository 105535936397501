import React from "react";
import styles from "./FeaturesBlock.module.scss";
import FeaturesHeader from "./FeaturesHeader";
import FeaturesBody from "./FeaturesBody";

const FeaturesBlock: React.FC = () => {
  return (
    <div className={styles.features__wrapper}>
      <FeaturesHeader />
      <FeaturesBody />
    </div>
  );
};

export default FeaturesBlock;
