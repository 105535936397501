import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import styles from "./AccordionWrapper.module.scss";

import InvoicesBlock from "../InvoicesBlock/index";
import RecurringPayments from "../RecurringPayments/index";
import QRCode from "../QRCode/index";
import ChatWrapper from "../Chat";
import AccordionItem from "./AccordionItem";
import AccordionContent from "./AccordionContent";
import AccordionItemBody from "./AccordionItemBody";

export interface IAccordion {
  id: number;
  title: string;
  subTitle: string;
  texts: string[];
  rightBlock: JSX.Element;
  mobileTexts: string[];
}

const accordions: IAccordion[] = [
  {
    id: 0,
    title: "Invoices",
    subTitle: "E-commerce choice",
    texts: [
      "This method is suitable for paying for goods or services",
      "You can set the price in a fiat currency so the payer chooses a cryptocurrency and pays a corresponding amount, or specify the preferable cryptocurrency right away, and the cryptocurrency address will be generated after choosing a network",
    ],
    mobileTexts: [
      `You can set the price in a fiat or crypro currency, and the payment address will be generated`,
    ],
    rightBlock: <InvoicesBlock />,
  },
  {
    id: 1,
    title: "Recurring payments",
    subTitle: "Subscription businesses choice",
    texts: [
      "Subscription-based payments for your business Your client needs to set it up just once to be charged regularly",
    ],
    mobileTexts: [
      "Subscription-based payments for your business Your client needs to set it up just once to be charged regularly",
    ],
    rightBlock: <RecurringPayments />,
  },
  {
    id: 2,
    title: "Host",
    subTitle: "Betting and iGaming choice",
    texts: [
      "Your clients top up their personal balances in any amount and can use funds to play and bet without delays",
    ],
    mobileTexts: [
      "Your clients top up their personal balances in any amount and can use funds to play and bet without delays",
    ],
    rightBlock: <QRCode />,
  },
  {
    id: 3,
    title: "Payment links",
    subTitle: "Single payment choice",
    texts: [
      "Create an invoice, share the link and get paid – it's an easy payment solution for your online business of any kind",
    ],
    mobileTexts: [
      "Create an invoice, share the link and get paid – it's an easy payment solution for your online business of any kind",
    ],
    rightBlock: <ChatWrapper />,
  },
];

const AccordionWrapper: React.FC = () => {
  const [activeRow, setActiveRow] = useState<IAccordion | null>(accordions[0]);

  useEffect(() => {
    if (!activeRow) {
      setActiveRow(accordions[0]);
    }
  }, [activeRow]);

  return (
    <div className={styles.block__wrapper}>
      <div className={styles.accordion__wrapper}>
        {accordions.map((item) => (
          <AccordionItem key={item.id} activeRow={activeRow} row={item} setActiveRow={setActiveRow}>
            <AnimatePresence initial={false}>
              {activeRow?.id === item?.id ? <AccordionItemBody row={item} /> : null}
            </AnimatePresence>

            <div className={styles.accordion__content_mobile}>
              <AnimatePresence exitBeforeEnter>
                {activeRow?.id === item?.id ? (
                  <div onClick={(e) => e.stopPropagation()}>
                    <AccordionContent
                      activeRowId={activeRow?.id}
                      activeComponent={activeRow?.rightBlock}
                    />
                  </div>
                ) : null}
              </AnimatePresence>
            </div>
          </AccordionItem>
        ))}
      </div>

      <div className={styles.accordion__content_desktop}>
        <AnimatePresence exitBeforeEnter>
          <AccordionContent activeRowId={activeRow?.id} activeComponent={activeRow?.rightBlock} />
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AccordionWrapper;
