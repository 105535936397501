import React from "react";
import styles from "./CRMSMintedBanner.module.scss";
import cn from "classnames";
import { useTranslation } from "next-i18next";
import CerticLogo from "public/img/CRMSMintedBanner/certik-logo.svg";
import UnicLogo from "public/img/CRMSMintedBanner/uniswap-logo.svg";

const longLangs = ["de", "fr", "ru", "uk", "kk", "pl", "pt"];

const CRMSMintedBanner = () => {
  const { t, i18n } = useTranslation("common");

  return (
    <div
      className={cn(styles.crms_banner, { [styles.long_lang]: longLangs.includes(i18n.language) })}
    >
      <div className={styles.crms_banner__content}>
        <div className={styles.content__text}>
          <h2>{t("CRMS Minted put your CRMS to work")}</h2>
          <p>
            {t("Start withdrawing and converting accumulated Cryptomus Tokens now")}
            <br />
            {t("Available on the Uniswap")}
          </p>
        </div>
        <div className={styles.content__services}>
          <a href="https://skynet.certik.com/projects/cryptomus" target="_blank" rel="noreferrer">
            <CerticLogo />
          </a>
          <div className={styles.divider} />
          <a href="https://app.uniswap.org/swap?chain=polygon" target="_blank" rel="noreferrer">
            <UnicLogo />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CRMSMintedBanner;
